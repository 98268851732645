<template lang="pug">
  footer.footer.mt-auto
    div.footer__decor-left
    div.footer__decor-right
    div.container
      div.footer__hero
        div.footer__content
          a(v-scroll-to="'#header'")(@click='clickBtn').logo
            img(src="~@/assets/images/logo.svg" alt="Logo")

          p.footer__content-text A simple tool for generating short links.

        navigation-block

      div.footer__copy
        p F4.TO © Copyright 2023

</template>

<script>

export default {
  name: 'FooterBlock',
  components: {
    NavigationBlock: () => import('@/components/NavigationBlock.vue'),
  },
  methods: {
    clickBtn () {
      let menuItem = document.querySelectorAll('.navigation__item')
      for(let i = 0; i < menuItem.length; i++) {
        menuItem[i].classList.remove('active');
        menuItem[0].classList.add('active');
      }
    }
  }
}

</script>
